import React, { useState } from "react";
import { GoogleOutlined, ApiOutlined, ShopOutlined } from "@ant-design/icons";
import ComingSoonBadge from "../components/ComingSoonBadge";
import { paragon } from "@useparagon/connect";
import { authenticateWithParagon } from "../paragonAuthHelper.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import IntegrationBox from "./IntegrationBox";
import intercom from "../integration_icons/intercom.png";

import zendesk from "../integration_icons/zendesk.png";
import gmail from "../integration_icons/gmail.png";

import chat from "../integration_icons/chat.png";
import whatsapp from "../integration_icons/whatsapp.png";
import sms from "../integration_icons/wechat.png";
import outlook from "../integration_icons/ms_outlook.png";
import phone from "../integration_icons/phone.png";
import instagram from "../integration_icons/instagram.png";
import facebook from "../integration_icons/fb_messenger.png";

import shopify from "../integration_icons/shopify.png";
import zapier from "../integration_icons/zapier.png";
import stripe from "../integration_icons/stripe.png";

const Integrations = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const integrations = [
    {
      name: "Gmail",
      description:
        "Connect your chatbot to Gmail and manage email interactions.",
      icon: <GoogleOutlined className="text-red-600 text-4xl" />,
    },
    {
      name: "Zapier",
      description: "Connect your chatbot with thousands of apps using Zapier.",
      icon: <ApiOutlined className="text-orange-500 text-4xl" />,
      comingSoon: true, // Zapier is marked as coming soon
    },
    {
      name: "Shopify",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: <ShopOutlined className="text-green-500 text-4xl" />,
      comingSoon: true, // Shopify is marked as coming soon
    },
  ];

  const CRM = [
    {
      name: "Intercom",
      description:
        "Have your AI Agent respond to customer inquiries in Intercom.",
      icon: intercom,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Zendesk",
      description:
        "Have your AI Agent respond to customer inquiries in Zendesk.",
      icon: zendesk,
      comingSoon: true,
      connected: false,
    },
  ];

  const COMMUNICATIONS = [
    {
      name: "LiveChat",
      description:
        "Connect your chatbot to Gmail and manage email interactions.",
      icon: chat,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Gmail",
      description:
        "Connect your chatbot to Gmail and manage email interactions.",
      icon: gmail,
      comingSoon: false,
      connected: false,
    },
    {
      name: "Outlook",
      description: "Connect your chatbot with thousands of apps using Zapier.",
      icon: outlook,
      comingSoon: true,
      connected: false,
    },
    {
      name: "SMS",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: sms,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Phone",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: phone,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Instagram DMs",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: instagram,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Facebook Messenger",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: facebook,
      comingSoon: true,
      connected: false,
    },
    {
      name: "WhatsApp",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: whatsapp,
      comingSoon: true,
      connected: false,
    },
  ];

  const DATA_AND_ACTIONS = [
    {
      name: "Zapier",
      description: "Connect your chatbot with thousands of apps using Zapier.",
      icon: zapier,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Shopify",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: shopify,
      comingSoon: true,
      connected: false,
    },
    {
      name: "Stripe",
      description:
        "Integrate your chatbot with Shopify to manage orders and customer inquiries.",
      icon: stripe,
      comingSoon: true,
      connected: false,
    },
  ];

  const handleCardClick = integration => {
    if (!integration.comingSoon) {
      setSelectedIntegration(integration);
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    const companyId = sessionStorage.getItem("company_id");

    if (!companyId) {
      console.error("Company ID not found in sessionStorage");
      return;
    }

    authenticateWithParagon(companyId)
      .then(() => {
        paragon.connect("gmail", {
          onSuccess: () => {
            console.log("Connected to Gmail successfully");
          },
          onError: error => {
            console.error("Error connecting to Gmail:", error);
          },
        });
      })
      .catch(error => {
        console.error("Error authenticating with Paragon:", error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="mt-12 mx-4 md:mx-24" style={{paddingBottom: '10px'}}>
        <div>
          <h1 className="text-lg sm:text-xl">Integrations</h1>
          <h4 className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Utilize integrations to communicate through all your channels,
            enable your AI agent to take actions and pull in data from your
            backend to provide excellent customer service and upselling
            capability.
          </h4>
        </div>

        <section className="mb-12">
          <h2 className="text-md font-semibold">Connect your CRM</h2>
          <p className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Connect your CRM to escalate handed-off tickets (coming soon) and
            have your AI Agent respond to tickets through your CRM instead of
            utilizing SupportMagic (coming soon).
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {CRM.map(integration => (
              <IntegrationBox
                integration={integration}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-md font-semibold">Communication Channels</h2>
          <p className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Connect your business communication channels to allow your AI to
            handle customer requests everywhere you receive them.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {COMMUNICATIONS.map(integration => (
              <IntegrationBox
                integration={integration}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-md font-semibold">Data & Actions</h2>
          <p className="text-sm text-gray-400 mb-8 min-h-8 max-h-20">
            Connect your tools to automate your support end to end. When you AI
            recognizes a customer intent, it can take action on your behalf.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {DATA_AND_ACTIONS.map(integration => (
              <IntegrationBox
                integration={integration}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
        </section>

        {/* Modal */}
        {isModalVisible && (
          <ConfirmationDialog
            isVisible={isModalVisible}
            onClose={handleCancel}
            onCancel={handleCancel}
            onConfirm={handleOk}
            title={`Enable ${selectedIntegration?.name}`}
            description={`Are you sure you want to enable the ${selectedIntegration?.name} integration?`}
            confirmLabel="Enable"
            cancelLabel="Cancel"
            useCase="positive"
          />
        )}
      </div>
    </>
  );
};

export default Integrations;
