import { useState } from "react";
import {
  RocketLaunchIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";

const DeployOptions = ({ externalHandleSelection, initialSelection }) => {
  const optionsMap = [
    {
      index: 0,
      title: "Embed code without user info",
      description: "For all users",
      type: "Basic",
    },
    {
      index: 1,
      title: "Embed code with user info",
      description: "For logged in users",
      type: "Intermediate",
    },
    {
      index: 2,
      title: "Embed with user verification",
      description: "Authenticates logged in users",
      type: "Advanced",
      disabled: true,
    },
  ];

  const [selected, setSelected] = useState(
    optionsMap.find(o => o.type === initialSelection).index
  );

  const handleSelect = index => {
    setSelected(index);
    externalHandleSelection(optionsMap[index].type);
  };

  const Option = ({ index, title, description, type, disabled }) => (
    <div
      className={`p-4 border rounded-2xl shadow-lg ${
        selected === index ? "border-primary border-2" : "border-gray-300"
      }`}
      onClick={() => handleSelect(index)}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="w-12 h-12 mr-3 rounded-lg border-2 border-grey-300 overflow-hidden flex items-center justify-center">
            <div className="w-6 h-6 flex items-center justify-center">
              <Square3Stack3DIcon className="text-gray-600" />
            </div>
          </div>
          <div>
            <div className="font-semibold text-sm text-gray-600">
              {title} <span className="font-normal text-xs">({type})</span>
            </div>
            <div className="text-sm text-gray-500">{description}</div>
          </div>
        </div>
        <div>
          {selected === index ? (
            <label class="relative flex items-center cursor-pointer">
              <input
                type="radio"
                name="radio"
                disabled={disabled}
                class="peer hidden"
              />
              <div class="w-6 h-6 rounded-full border-8 border-[#9E77ED] flex items-center justify-center">
                <div class="w-2 h-2 bg-white rounded-full"></div>
              </div>
            </label>
          ) : (
            <label class="relative flex items-center cursor-pointer">
              <input
                type="radio"
                name="radio"
                disabled={disabled}
                class="peer hidden"
                onChange={() => handleSelect(index)}
              />
              <div class="w-6 h-6 rounded-full border-2 border-gray-300 flex items-center justify-center">
                <div class="w-2 h-2 bg-white rounded-full"></div>
              </div>
            </label>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      {optionsMap.map(option => (
        <Option
          key={option.index}
          index={option.index}
          title={option.title}
          description={option.description}
          type={option.type}
          disabled={option.disabled}
        />
      ))}
    </div>
  );
};

export default DeployOptions;
