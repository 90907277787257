import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import api from "../api";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_51OGqDZJEWwsAX37VhKDC5IB3OCv0LPfmX1NoAYezKtJXbx6zzTDBAW9f4XlhYsDC8c1ZNKo158PUz7IiTx1XTlcv00za7C2QVY");
console.log(process.env);
const CheckoutPage = ({planId, cost}) => {
  const handleCheckout = async () => {
    const stripe = await stripePromise;

    const response = await api.post("api/company/create_checkout_session", {plan_price_id: planId});
    const session = await response.data;
    if (session.id) {
      await stripe.redirectToCheckout({ sessionId: session.id });
    } else {
      console.error("Error creating checkout session:", session.error);
    }
  };

  return (
    <div>
      <button onClick={handleCheckout}
        className="mt-4 w-full bg-emerald-600 text-white px-3 py-2 rounded-md text-sm font-semibold hover:bg-emerald-500"
      >
        Choose ${cost}
      </button>
    </div>
  );
};

export default CheckoutPage;
