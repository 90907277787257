import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import Banner from "../components/Banner";
import { useBanner } from "../hooks/useBanner";

import {
  Bars3Icon,
  InboxIcon,
  XMarkIcon,
  ClipboardDocumentCheckIcon,
  DocumentChartBarIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { useBillingContext } from "../BillingContext";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import plainUserAvatar from "../imgs/icons/emptyUserAvatar.png";
import SideBar from "./SideBar.jsx";

import api from "../api.js";

const navigationItems = [
  {
    name: "Tasks",
    href: "/dashboard/tasks",
    counter: true,
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: "Inbox",
    href: "/dashboard/ai-inbox",
    icon: InboxIcon,
  },
  {
    name: "Setup",
    expand: true,
    scope: "setup",
    icon: DocumentChartBarIcon,
    children: [
      {
        name: "Knowledge Sources",
        href: "/setup/knowledge-sources",
      },
      {
        name: "Branding",
        href: "/setup/branding",
      },
      {
        name: "Intents",
        href: "/setup/intents",
      },
      {
        name: "Test",
        href: "/setup/chatbot",
      },
      {
        name: "Deploy",
        href: "/setup/deploy",
      },
    ],
  },
  {
    name: "Integrations",
    href: "/dashboard/integrations",
    icon: LinkIcon,
  },
];

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { billingInfo, resetBilling } = useBillingContext();

  const [user, setUser] = useState({
    name: "",
    email: "",
    avatar: plainUserAvatar,
  });

  const [taskStats, setTaskStats] = useState({ open: { count: 0 } });
  const { isVisible, message, type, showBanner, hideBanner } = useBanner();

  useEffect(() => {
    resetBilling();
  }, [resetBilling]);

  useEffect(() => {
    if (billingInfo) {
      const totalLimit = billingInfo.subscription_limit;
      const messagesUsed = billingInfo.credits_consumed;
      const percentage = (messagesUsed / totalLimit) * 100;

      if (percentage >= 80) {
        showBanner(
          `This plan's credits have been ${percentage}% consumed. Credits consumed: ${messagesUsed}`,
          "warning"
        );
      }

      if (messagesUsed > totalLimit) {
        showBanner(
          `This plan's credits have been consumed. Please upgrade your plan Credits consumed: ${messagesUsed}`,
          "error"
        );
      }
    }
  }, [billingInfo]);

  useEffect(() => {
    api.get(`/api/user`).then(response => {
      if (response.data && response.data.picture_url) {
        setUser({
          avatar: response.data.picture_url,
          name: `${response.data.first_name} ${response.data.last_name}`,
          email: response.data.email,
        });
      }
    });

    api
      .get("/api/task/stats", {
        params: { chatbot_uuid: localStorage.getItem("chatbot_uuid") },
      })
      .then(response => {
        if (response.data) {
          setTaskStats({ open: { count: response.data.tasks.open } });
        }
      });
  }, []);

  useEffect(() => {
    if (
      location.pathname.endsWith("/dashboard/") ||
      location.pathname.endsWith("/dashboard")
    ) {
      navigate("/dashboard/ai-inbox");
      return;
    }

    if (
      location.pathname.endsWith("/setup/") ||
      location.pathname.endsWith("/setup")
    ) {
      navigate("/setup/knowledge-sources");
    }
  }, [location.pathname, navigate]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const currentPath = location.pathname;
  const navigation = navigationItems.map(item => ({
    ...item,
    current:
      item.href === currentPath ||
      (item.scope === "setup" && currentPath.startsWith("/setup")),
  }));

  return (
    <div>
      <Dialog
        open={sidebarOpen}
        onClose={setSidebarOpen}
        className="relative z-50 lg:hidden"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full border border-gray-300"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => setSidebarOpen(false)}
                  className="-m-2.5 p-2.5"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </button>
              </div>
            </TransitionChild>

            <SideBar
              navigation={navigation}
              user={user}
              currentPath={currentPath}
              taskStats={taskStats}
            />
          </DialogPanel>
        </div>
      </Dialog>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <SideBar
          navigation={navigation}
          user={user}
          currentPath={currentPath}
          taskStats={taskStats}
        />
      </div>

      <div className="lg:pl-72 bg-white">
        <div className="sticky top-0 z-40 flex h-16 lg:h-0 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            onClick={() => setSidebarOpen(true)}
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1"></div>
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <div
                aria-hidden="true"
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              />
            </div>
          </div>
        </div>
        {isVisible && (
          <Banner message={message} type={type} onClose={hideBanner} />
        )}

        <main className="bg-white">
          <div className="">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
