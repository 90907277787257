export const fakeConversations = {
  sample_thread: [
    {
      message: "Oi",
      sentTime: "2024-08-23T21:56:53.530994",
      sender: "user",
      direction: "outgoing",
    },
    {
      message:
        "It looks like you've included some CSS code in your message. How can I assist you with it? If you need help with a specific issue or have a question about our services, please let me know. If you'd prefer to speak with a human customer success agent, I can connect you.",
      sentTime: "2024-08-23T21:56:55.795743",
      sender: "admin",
      direction: "incoming",
    },
    {
      message: "nice\n",
      sentTime: "2024-08-23T21:57:24.917696",
      sender: "ChatBot",
      direction: "incoming",
    },
    {
      message: "Oi",
      sentTime: "2024-08-23T21:56:53.530994",
      sender: "user",
      direction: "outgoing",
    },
    {
      message:
        "It looks like you've included some CSS code in your message. How can I assist you with it? If you need help with a specific issue or have a question about our services, please let me know. If you'd prefer to speak with a human customer success agent, I can connect you.",
      sentTime: "2024-08-23T21:56:55.795743",
      sender: "admin",
      direction: "incoming",
    },
    {
      message: "nice\n",
      sentTime: "2024-08-23T21:57:24.917696",
      sender: "ChatBot",
      direction: "incoming",
    },
    {
      message: "Oi",
      sentTime: "2024-08-23T21:56:53.530994",
      sender: "user",
      direction: "outgoing",
    },
    {
      message:
        "It looks like you've included some CSS code in your message. How can I assist you with it? If you need help with a specific issue or have a question about our services, please let me know. If you'd prefer to speak with a human customer success agent, I can connect you.",
      sentTime: "2024-08-23T21:56:55.795743",
      sender: "admin",
      direction: "incoming",
    },
    {
      message: "nice\n",
      sentTime: "2024-08-23T21:57:24.917696",
      sender: "ChatBot",
      direction: "incoming",
    },
  ],
  total_conversations: 10,
  conversations: [
    {
      conversation_id: 1,
      conversation_uuid: "df68cf2d-b4f8-4e7e-8723-b35783aa0dab",
      created_at: "2024-08-20T20:35:52.097541",
      handed_off: false,
      last_message_time: "2024-08-20T20:35:52.097541",
      summary: null,
      resolved: true,
      intents: [],
      chat_inputs: [],
      chat_responses: [],
      user_avatar: "https://randomuser.me/api/portraits/lego/1.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "User123",
    },
    {
      conversation_id: 2,
      conversation_uuid: "d4ca7c2c-61cf-4e83-812f-38e7c15f0acb",
      created_at: "2024-08-20T20:35:53.180894",
      handed_off: false,
      last_message_time: "2024-08-23T21:56:21.550232",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: [
        "2024-08-20T20:36:06.562473",
        "2024-08-20T20:36:58.471366",
        "2024-08-20T20:38:03.566446",
        "2024-08-20T20:39:41.041257",
        "2024-08-20T20:44:09.757821",
        "2024-08-20T20:46:17.689654",
        "2024-08-20T20:46:30.101513",
        "2024-08-20T20:46:56.077333",
        "2024-08-23T21:51:57.227356",
        "2024-08-23T21:52:06.285308",
        "2024-08-23T21:52:26.808162",
      ],
      chat_responses: [
        "2024-08-20T20:36:08.843353",
        "2024-08-20T20:37:00.102434",
        "2024-08-20T20:38:05.975002",
        "2024-08-20T20:39:43.110202",
        "2024-08-20T20:44:12.425939",
        "2024-08-20T20:46:19.753317",
        "2024-08-20T20:46:31.688486",
        "2024-08-20T20:46:58.179356",
        "2024-08-23T21:51:48.736152",
        "2024-08-23T21:51:59.389221",
        "2024-08-23T21:52:08.199658",
        "2024-08-23T21:52:28.376498",
        "2024-08-23T21:56:21.550232",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/2.jpg",
      location: "Portugal",
      twoLetterCountryCode: "PT",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Purple Rhino",
    },
    {
      conversation_id: 3,
      conversation_uuid: "c3358b91-716c-41b8-ade9-86455a16ba87",
      created_at: "2024-07-20T22:58:28.364478",
      handed_off: false,
      last_message_time: "2024-07-20T22:58:33.520658",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: ["2024-07-20T22:58:32.149646"],
      chat_responses: ["2024-07-20T22:58:33.520658"],
      user_avatar: "https://randomuser.me/api/portraits/lego/3.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Magnificent Unicorn",
    },
    {
      conversation_id: 4,
      conversation_uuid: "b256e1e0-76d3-4343-a0a9-9eabfca86596",
      created_at: "2024-07-20T22:58:58.663597",
      handed_off: false,
      last_message_time: "2024-08-19T21:38:33.282425",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: [
        "2024-07-20T23:04:54.308203",
        "2024-07-20T23:05:12.972974",
        "2024-08-19T21:38:30.709397",
      ],
      chat_responses: [
        "2024-07-20T22:59:34.335101",
        "2024-07-20T23:04:55.406507",
        "2024-07-20T23:05:14.445383",
        "2024-08-16T18:22:48.772288",
        "2024-08-19T21:38:33.282425",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/4.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Dashing Eagle",
    },
    {
      conversation_id: 5,
      conversation_uuid: "5f92648c-2b45-4807-a9ea-e942179e59de",
      created_at: "2024-08-20T20:51:06.058523",
      handed_off: true,
      last_message_time: "2024-08-21T22:24:07.269004",
      summary:
        "The user is unclear about their request and needs assistance clarifying their needs. \n\nFollow-up action: Reach out to the user with a prompt asking them to describe their issue or question in more detail to provide better support.",
      resolved: false,
      intents: [],
      chat_inputs: [
        "2024-08-20T20:51:12.302671",
        "2024-08-20T20:56:29.649118",
        "2024-08-20T20:56:40.759293",
        "2024-08-20T20:59:17.043733",
        "2024-08-20T21:00:29.335859",
        "2024-08-20T21:00:45.835669",
        "2024-08-20T21:01:31.149121",
        "2024-08-20T21:44:49.993276",
        "2024-08-21T21:30:33.018466",
        "2024-08-21T21:30:55.226596",
        "2024-08-21T21:35:39.797335",
        "2024-08-21T21:37:16.456886",
        "2024-08-21T21:38:08.495173",
        "2024-08-21T22:19:22.084511",
        "2024-08-21T22:22:20.172481",
        "2024-08-21T22:22:54.531709",
        "2024-08-21T22:23:53.332263",
        "2024-08-21T22:24:05.379074",
      ],
      chat_responses: [
        "2024-08-20T21:44:52.674115",
        "2024-08-21T21:30:56.924249",
        "2024-08-21T21:30:35.352688",
        "2024-08-21T21:34:12.700278",
        "2024-08-20T20:51:14.186522",
        "2024-08-20T20:56:31.684483",
        "2024-08-20T20:56:42.613685",
        "2024-08-20T20:59:20.206240",
        "2024-08-20T21:00:31.381796",
        "2024-08-20T21:00:48.241540",
        "2024-08-20T21:01:33.335984",
        "2024-08-20T21:31:57.934673",
        "2024-08-21T21:35:41.817083",
        "2024-08-21T21:38:10.196024",
        "2024-08-21T21:37:18.418000",
        "2024-08-21T22:19:24.227399",
        "2024-08-21T22:19:43.968369",
        "2024-08-21T22:22:21.952889",
        "2024-08-21T22:22:45.775132",
        "2024-08-21T22:22:56.379809",
        "2024-08-21T22:23:46.669381",
        "2024-08-21T22:23:55.224837",
        "2024-08-21T22:24:07.269004",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/5.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Quiet Penguin",
    },
    {
      conversation_id: 6,
      conversation_uuid: "0b32e422-d402-492f-9639-b443aa9b64d6",
      created_at: "2024-08-20T21:01:47.070335",
      handed_off: false,
      last_message_time: "2024-08-20T21:22:38.511003",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: [
        "2024-08-20T21:01:50.270537",
        "2024-08-20T21:02:36.109314",
        "2024-08-20T21:02:50.051346",
        "2024-08-20T21:07:15.515377",
        "2024-08-20T21:07:34.881881",
      ],
      chat_responses: [
        "2024-08-20T21:07:18.251357",
        "2024-08-20T21:07:36.700569",
        "2024-08-20T21:01:51.989213",
        "2024-08-20T21:02:37.890459",
        "2024-08-20T21:02:52.126465",
        "2024-08-20T21:22:38.511003",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/6.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Bold tiger",
    },
    {
      conversation_id: 7,
      conversation_uuid: "e7cc19fb-8d81-4d7f-bfa8-d33a070aa5de",
      created_at: "2024-08-23T21:56:49.692462",
      handed_off: false,
      last_message_time: "2024-08-23T21:57:24.917696",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: ["2024-08-23T21:56:53.530994"],
      chat_responses: [
        "2024-08-23T21:56:55.795743",
        "2024-08-23T21:57:24.917696",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/7.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Swift Falcon",
    },
    {
      conversation_id: 8,
      conversation_uuid: "df68cf2d-b4f8-4e7e-8723-b35783aa0dab",
      created_at: "2024-08-20T20:35:52.097541",
      handed_off: false,
      last_message_time: "2024-08-20T20:35:52.097541",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: [],
      chat_responses: [],
      user_avatar: "https://randomuser.me/api/portraits/lego/8.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Gentle Giraffe",
    },
    {
      conversation_id: 9,
      conversation_uuid: "d4ca7c2c-61cf-4e83-812f-38e7c15f0acb",
      created_at: "2024-08-20T20:35:53.180894",
      handed_off: false,
      last_message_time: "2024-08-23T21:56:21.550232",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: [
        "2024-08-20T20:36:06.562473",
        "2024-08-20T20:36:58.471366",
        "2024-08-20T20:38:03.566446",
        "2024-08-20T20:39:41.041257",
        "2024-08-20T20:44:09.757821",
        "2024-08-20T20:46:17.689654",
        "2024-08-20T20:46:30.101513",
        "2024-08-20T20:46:56.077333",
        "2024-08-23T21:51:57.227356",
        "2024-08-23T21:52:06.285308",
        "2024-08-23T21:52:26.808162",
      ],
      chat_responses: [
        "2024-08-20T20:36:08.843353",
        "2024-08-20T20:37:00.102434",
        "2024-08-20T20:38:05.975002",
        "2024-08-20T20:39:43.110202",
        "2024-08-20T20:44:12.425939",
        "2024-08-20T20:46:19.753317",
        "2024-08-20T20:46:31.688486",
        "2024-08-20T20:46:58.179356",
        "2024-08-23T21:51:48.736152",
        "2024-08-23T21:51:59.389221",
        "2024-08-23T21:52:08.199658",
        "2024-08-23T21:52:28.376498",
        "2024-08-23T21:56:21.550232",
      ],
      user_avatar: "https://randomuser.me/api/portraits/lego/9.jpg",
      location: "ES",
      twoLetterCountryCode: "ES",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Curious Cat",
    },
    {
      conversation_id: 10,
      conversation_uuid: "c3358b91-716c-41b8-ade9-86455a16ba87",
      created_at: "2024-07-20T22:58:28.364478",
      handed_off: false,
      last_message_time: "2024-07-20T22:58:33.520658",
      summary: null,
      resolved: false,
      intents: [],
      chat_inputs: ["2024-07-20T22:58:32.149646"],
      chat_responses: ["2024-07-20T22:58:33.520658"],
      user_avatar: "https://randomuser.me/api/portraits/lego/0.jpg",
      location: "USA",
      twoLetterCountryCode: "US",
      email: "email@org.com",
      phone: "+555 2934 2392 3932",
      url: "https://nugg.com",
      userHandle: "@sampleHandle",
      username: "Noble Lion",
    },
  ],
};
