import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/globe-02.svg";
import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";
import GlobeInput from "../components/GlobeInput";
import { validateURL } from "../../../utils/validateURL";

const AddWebsiteModal = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  setLoading,
  api,
}) => {
  const [urls, setUrls] = useState([{ value: "", isValid: true }]);

  const addNewUrlField = () => {
    setUrls([...urls, { value: "", isValid: true }]);
  };

  const updateUrl = (index, value) => {
    const isValid = validateURL(value);
    const updatedUrls = [...urls];
    updatedUrls[index] = { value, isValid };
    setUrls(updatedUrls);
  };

  const removeUrlField = index => {
    const updatedUrls = urls.filter((_, i) => i !== index);
    setUrls(updatedUrls);
  };
  const handleAddSource = async () => {
    setLoading(true);
    try {
      const promises = urls.map(url =>
        api.post("/api/process-url", { url: url.value })
      );

      const responses = await Promise.all(promises);

      const allSuccessful = responses.every(
        response => response.status === 200
      );

      if (allSuccessful) {
        onSuccess();
        onClose();
      } else {
        alert("Some URLs failed to process");
      }
    } catch (error) {
      console.error("Error processing URLs:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const actionDisabled = !urls.every(
    url => url.isValid && url.value.length > 0
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center">
          <TitleIcon
            width={60}
            height={60}
            className="p-4 ml-6 mt-6 border rounded-xl"
          />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">
            Add Website URL(s)
          </h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            Use top level domains like <code>https://example.com</code> to scan
            your whole site and subfolders like{" "}
            <code>https://example.com/home</code> to scan just that page.
          </p>
        </div>

        <div className="mx-6">
          <div className="mt-6 text-xs text-gray-800 font-semibold">URL(s)</div>
          <div className="mt-2 space-y-2">
            {urls.map((url, index) => (
              <div key={index} className="flex items-center space-x-2">
                <div className="flex-1">
                  <GlobeInput
                    value={url.value}
                    isValid={url.isValid}
                    setValue={e => updateUrl(index, e.target.value)}
                  />
                </div>
                {index > 0 && (
                  <button
                    onClick={() => removeUrlField(index)}
                    className="text-gray-400 hover:text-red-500 focus:outline-none"
                  >
                    &times;
                  </button>
                )}
              </div>
            ))}
          </div>

          <button
            onClick={addNewUrlField}
            className="my-3 text-sm text-secondary font-semibold hover:underline focus:outline-none"
          >
            + Add another
          </button>
        </div>

        <div className="flex items-center py-3">
          <button
            onClick={goBack}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            <BackButton className="mr-2" />
            Go Back
          </button>
          <button
            onClick={handleAddSource}
            className={`flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md ${actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"} font-bold text-white ml-1 mr-6 py-3 rounded-md`}
            disabled={actionDisabled}
          >
            {urls.length === 1 ? "Add Source" : "Add Sources"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddWebsiteModal;
