import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/intercom.svg";

import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { ReactComponent as BackButton } from "../../../imgs/icons/buttons/back.svg";

import { ReactComponent as IntercomIcon } from "../../../imgs/icons/intercom.svg";

import { ReactComponent as UploadFileIcon } from "../../../imgs/icons/uploadFile.svg";

import { authenticateWithParagon } from "../../../paragonAuthHelper.js";
import { paragon } from "@useparagon/connect";

const AddIntercomModal = ({
  isOpen,
  onClose,
  goBack,
  onSuccess,
  loading,
  setLoading,
  api,
}) => {
  const handleIntercomSignIn = async () => {
    try {
      const companyId = sessionStorage.getItem("company_id");
      if (!companyId) {
        console.error("Company ID not found in sessionStorage");
        return;
      }

      await authenticateWithParagon(companyId);

      paragon.connect("intercom", {
        onSuccess: () => {
          console.log("Intercom login -> good");
        },
        onError: error => {
          console.error("Error connecting to Intercom:", error);
        },
      });
    } catch (error) {
      console.error("Error during Intercom Sign-In:", error);
    }
  };

  const callParagonWorkflow = async () => {
    try {
      setLoading(true);
      const response = await paragon.workflow(
        "5422dcf8-968a-4a8a-b8f1-0c4798dbed56",
        {}
      );

      await api.post("/api/save-embedding", {
        data: response,
        source_type: "INTERCOM",
      });

      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error calling Paragon workflow:", error);
      alert("Error calling Paragon workflow");
    } finally {
      setLoading(false);
    }
  };

  const [numberConversations, setNumberConversations] = useState(50);
  const [numberSelected, setNumberSelected] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center">
          <TitleIcon
            width={60}
            height={60}
            className="p-4 ml-6 mt-6 border rounded-xl"
          />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">
            Intercom Conversations
          </h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            Connect to your support team’s Intercom account to pull in
            conversation history and train your AI Support Agent to better
            respond to customer inquiries.
          </p>
        </div>

        {numberSelected && (
          <div className="flex flex-col">
            <div className="mx-6">
              <div className="flex flex-col items-center">
                <p className="mt-4">Pull conversations from Intercom chat.</p>

                <button
                  className="bg-black text-sm font-bold text-white px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full justify-center mx-auto mb-2"
                  onClick={handleIntercomSignIn}
                >
                  Sign in to Intercom
                  <IntercomIcon className="h-5 w-5 ml-2" />
                </button>

                <label className="cursor-pointer text-sm font-bold text-gray-400 px-5 py-2.5 cursor-pointer border border-gray-300 rounded-sm flex items-center w-full justify-center mx-auto">
                  Upload Intercom CSV file
                  <UploadFileIcon className="h-5 w-5 ml-2" />
                  <input
                    type="file"
                    className="hidden"
                    onClick={callParagonWorkflow}
                  />
                </label>

                <a
                  href="https://www.intercom.com/help/en/articles/2046229-export-your-conversations-data"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 underline font-bold text-xs pb-10 mx-auto text-center justify-center mt-5"
                >
                  How to export conversation data from Intercom
                </a>
              </div>
            </div>

            <div className="border-b mb-1"></div>

            <div className="flex justify-start py-3">
              <button
                onClick={() => setNumberSelected(false)}
                className="flex p-5 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
              >
                <BackButton className="mr-2" />
                Go Back
              </button>
            </div>
          </div>
        )}

        {!numberSelected && (
          <div>
            <div className="mb-4 mx-6">
              <div className="flex mt-6 mb-1 text-xs text-gray-600 font-semibold">
                How many conversations would you like us to pull?
              </div>
              <div className="flex mb-2 text-xs text-gray-600">
                The more information you provide your AI Agent, the better it
                will perform. All data is secured and can be deleted at any
                time.
              </div>
              <select
                className="mt-3 px-2 py-3 rounded-md bg-white border border-gray-300 ring-opacity-50 w-full"
                value={numberConversations}
                onChange={e => {
                  // handleInputChange(e.target.value)
                  setNumberConversations(e.target.value);
                }}
              >
                <option value="50">50 conversation threads</option>
                <option value="100">100 conversation threads</option>
                <option value="150">150 conversation threads</option>
              </select>

              <div className="flex mt-6 mb-1 text-sm text-gray-600 font-semibold">
                Pull Snippets/Macros
              </div>
              <div className="flex mb-2 text-xs text-gray-600">
                These are all your stored answers to specific questions in
                Intercom that your support uses to answer questions faster.
              </div>
            </div>

            <div className="border-b mb-1"></div>

            <div className="flex justify-end py-3">
              <button
                onClick={goBack}
                className="flex p-5 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
              >
                <BackButton className="mr-2" />
                Go Back
              </button>
              <button
                className="flex p-5 text-md leading-5 bg-secondary border border-primary shadow-md hover:opacity-75 font-bold text-white ml-1 mr-6 py-3 rounded-md"
                onClick={() => setNumberSelected(true)}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddIntercomModal;
