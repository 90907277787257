import React, { useCallback, useEffect, useState } from "react";
import SetupTab from "./components/SetupTab.jsx";
import api, { WIDGET_URL } from "../../api";
import { useCompanyApi } from "../../hooks/useCompanyApi";

const Chatbot = () => {
  const { company, fetchCompany } = useCompanyApi();
  const [chatbotUuid, setChatbotUuid] = useState(
    localStorage.getItem("chatbot_uuid") || ""
  );

  const [greeting, setGreeting] = useState("");
  const [prompts, setPrompts] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [aiEmployeeName, setAiEmployeeName] = useState("");
  const [chatColor, setChatColor] = useState("#C8C");
  const [buttonColor, setButtonColor] = useState("#C8C");
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [chatbotPictureUrl, setChatbotPictureUrl] = useState("");
  const [isDataLoad, setIsDataLoad] = useState(false);

  useEffect(() => {
    fetchCompany();
    setIframeData();
  }, [chatbotUuid]);

  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
      setCompanyImageUrl(company.picture_url);
    }
  }, [company]);

  const setIframeData = useCallback(async () => {
    if (!isDataLoad) {
      const response = await api.get(`/api/chatbot/${chatbotUuid}`);
      const chatbot = response.data;
      setGreeting(chatbot.greeting);
      setPrompts(chatbot.prompt_text || "");
      setAiEmployeeName(chatbot.name);
      setButtonColor(`#${chatbot.button_color}`);
      setChatColor(`#${chatbot.chat_color}`);
      setIsDataLoad(true);
    }
  }, [chatbotUuid]);

  useEffect(() => {
    setIframeData();
    if (isDataLoad && chatbotUuid) {
      const iframe = document.getElementById("bonsai-widget-iframe");
      if (iframe) {
        iframe.onload = () => {
          iframe.contentWindow.postMessage(
            {
              type: "SET_CUSTOM_OPTIONS",
              resetConversation: false,
              url: window.location.href,
              tenantId: chatbotUuid,
              backgroundColor: chatColor,
              secondaryColor: buttonColor,
              fontColor: "#FFFFFF",
              companyName: companyName,
              companyLogo: companyImageUrl,
            },
            "*"
          );
        };
        iframe.src = iframe.src;
      }
    }
  }, [company]);

  return (
    <div className="mt-12 mx-4 md:mx-24">
      <SetupTab
        title={"Test"}
        subtitle={
          "Test out your AI employee’s ability to answer your company questions and handle end to end customer support."
        }
        activeTab={"chatbot"}
      />
      <div className="min-h-screen flex flex-col lg:flex-row">
        <iframe
          id="bonsai-widget-iframe"
          src={`${WIDGET_URL}/widgetEntry.html`}
          allow="geolocation"
          title="Live Chat Widget"
          style={{
            border: "none",
            width: "870px",
            height: "1000px",
            position: "relative",
            bottom: "5px",
            right: "5px",
            zIndex: 9999,
          }}
        />
      </div>
    </div>
  );
};

export default Chatbot;
