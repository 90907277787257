import React from "react";
import ReactMarkdown from "react-markdown";

import GenericAvatar from "./GenericAvatar";
import { PaperClipIcon } from "@heroicons/react/24/outline";

const ThreadMessages = ({
  messages,
  avatar,
  firstName = "Unknown",
  lastName = "User",
}) => {
  const senderTitleMap = {
    ChatBot: "AI Bot",
    admin: "You",
    user: `${firstName} ${lastName}`,
  };

  return (
    <>
      {messages.map((message, index) => (
        <div
          key={index}
          className={`flex my-5 ${
            message.sender === "user" ? "justifystart" : "justify-end"
          }`}
        >
          {" "}
          {message.sender === "user" && (
            <div className="mr-3">
              {avatar && (
                <div>
                  <div className="min-w-8 w-8 h-8 overflow-hidden rounded-full border-2 border-gray-300">
                    <img
                      src={avatar}
                      alt="User Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
              {!avatar && (
                <GenericAvatar firstName={firstName} lastName={lastName} />
              )}
            </div>
          )}
          <div
            className={`flex flex-col ${message.sender === "user" ? "w-[75%]" : "w-[55%]"}`}
          >
            <div className="flex justify-between items-center">
              <span className="text-sm font-semibold text-gray-800">
                {senderTitleMap[message.sender]}
              </span>
              <span className="text-xs text-gray-500">{message.sentTime}</span>
            </div>

            {message.attachment ? (
              <div
                className={`w-full mt-1 pt-3 px-4 rounded-lg ${
                  message.sender === "user"
                    ? "bg-gray-50  border border-gray-200 rounded-tl-none"
                    : `rounded-tr-none text-white ${message.sender === "admin" ? "bg-blue-500" : "bg-secondary"}`
                }`}
              >
                <a
                  href={message.attachmentLink}
                  target="_blank"
                  className="flex flex-justify mb-3 underline"
                >
                  <PaperClipIcon className="h-4 min-h-4 w-4 min-w-4 mr-1" />
                  <span className="text-ellipsis overflow-hidden">
                    {message.attachmentName}
                  </span>
                </a>
              </div>
            ) : (
              <div
                className={`mt-1 pt-3 px-4 rounded-lg ${
                  message.sender === "user"
                    ? "bg-gray-50  border border-gray-200 rounded-tl-none"
                    : `rounded-tr-none text-white ${message.sender === "admin" ? "bg-blue-500" : "bg-secondary"}`
                }`}
              >
                <ReactMarkdown>{message.message}</ReactMarkdown>
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default ThreadMessages;
