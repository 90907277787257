import React, { useState, useEffect, useRef } from "react";

import {
  ArrowLeftIcon,
  FaceSmileIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import ThreadMessages from "./ThreadMessages.jsx";

import EmojiPicker from "emoji-picker-react";
import {
  EllipsisHorizontalIcon,
  PaperClipIcon,
} from "@heroicons/react/20/solid";
import ErrorModal from "../../components/ErrorModal.jsx";

const Thread = ({
  selectedThread,
  setSelectedThread,
  messages,
  fetchThread,
  chatbotUuid,
  api,
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleInputChange = e => {
    setNewMessage(e.target.value);
  };

  const handleUploadAttachment = async () => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes

    if (filePreview.size > MAX_FILE_SIZE) {
      setErrorModalMsg("File size exceeds 10MB. Please upload a smaller file.");
      alert("File size exceeds 10MB. Please upload a smaller file.");
      return;
    }

    setIsTyping(true);

    try {
      const formData = new FormData();
      formData.append("file", filePreview);

      const config = {
        onUploadProgress: progressEvent => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentage);
        },
      };

      const attachEndpoint = `/api/chatbot/${chatbotUuid}/conversation/${selectedThread.conversation_uuid}/attach`;

      await api.post(attachEndpoint, formData, config);

      fetchThread({ thread: selectedThread });

      setNewMessage("");
      setFilePreview(null);
      setFileName(null);
      setUploadProgress(0);
    } catch (err) {
      setErrorModalMsg("Error attaching file, please contact us for help.");
    } finally {
      setIsTyping(false);
    }
  };

  const handleSendMessage = async () => {
    setIsTyping(true);

    try {
      const payload = filePreview
        ? {
            file: filePreview,
            fileName,
            company_id: Number(sessionStorage.getItem("company_id")),
            chatbot_uuid: chatbotUuid,
            conversation_uuid: selectedThread.conversation_uuid,
          }
        : {
            text: newMessage,
            company_id: Number(sessionStorage.getItem("company_id")),
            chatbot_uuid: chatbotUuid,
            conversation_uuid: selectedThread.conversation_uuid,
          };

      await api.post("/api/save-chat-response", payload);

      fetchThread({ thread: selectedThread });

      setNewMessage("");
      setFilePreview(null);
      setFileName(null);
    } catch (err) {
      setErrorModalMsg("Error sending message, please contact us for help.");

      console.error("Failed to send message", err);
    } finally {
      setIsTyping(false);
    }
  };

  const handleEmojiSelect = emojiObject => {
    setNewMessage(prev => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setFilePreview(file);
      setFileName(file.name);
      setNewMessage("");
    }
  };

  const removeFilePreview = () => {
    setFilePreview(null);
    setFileName(null);
  };

  const [errorModalMsg, setErrorModalMsg] = useState(null);

  return (
    <div
      className={`flex-grow h-full p-4
        ${!selectedThread && "hidden sm:flex"}`}
    >
      <ErrorModal
        isOpen={errorModalMsg}
        onClose={() => {
          setErrorModalMsg(null);
          setUploadProgress(0);
        }}
        errorMessage={errorModalMsg}
      />
      <div className="xs:flex sm:hidden">
        <ArrowLeftIcon
          onClick={() => setSelectedThread(undefined)}
          aria-hidden="true"
          className="ml-2 h-8 w-8 text-primary"
        />
      </div>
      {selectedThread ? (
        <div className="flex flex-col h-full">
          <div className="flex-1 overflow-y-auto p-4">
            <h2 className="text-lg font-bold">{selectedThread.title}</h2>
            <div className="mt-2">
              <ThreadMessages
                messages={messages}
                firstName={selectedThread.customer.first_name}
                lastName={selectedThread.customer.last_name}
                avatar={selectedThread.customer.picture_url}
              ></ThreadMessages>
            </div>
            <div ref={messageEndRef} />
          </div>

          <div className="sticky bottom-0 bg-white p-2">
            <div className="flex items-center space-x-4">
              <div className="relative w-full">
                {filePreview ? (
                  <div className="relative w-full">
                    <textarea
                      className="w-full p-2 border border-gray-300 border-2 text-gray-400 rounded-lg pr-10 resize-none overflow-y-auto relative"
                      rows={4}
                      value={fileName}
                      disabled={true}
                    ></textarea>

                    {filePreview && uploadProgress > 0 && (
                      <div className="relative w-10/12">
                        <div
                          className="absolute bottom-2 left-2 h-1 bg-blue-500"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                    )}
                  </div>
                ) : (
                  <textarea
                    className="w-full p-2 border border-gray-300 border-2 rounded-lg pr-10 resize-none overflow-y-auto"
                    rows={4}
                    placeholder="Send a message"
                    value={newMessage}
                    onChange={handleInputChange}
                  ></textarea>
                )}

                <div className="actions">
                  {filePreview ? (
                    <>
                      <button
                        className="absolute right-24 bottom-2 text-red-600"
                        onClick={removeFilePreview}
                      >
                        <XMarkIcon className="h-12 w-12" />
                      </button>

                      <button
                        className={`absolute right-2 bottom-2 p-4 py-3 text-sm font-bold bg-secondary shadow hover:bg-primary text-white rounded-lg transition-all duration-200 ease-in-out
                  ${isTyping || (!newMessage && !filePreview) ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleUploadAttachment}
                        disabled={isTyping || (!newMessage && !filePreview)}
                      >
                        Attach
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className={`absolute right-2 bottom-2 p-4 py-3 text-sm font-bold bg-secondary shadow hover:bg-primary text-white rounded-lg transition-all duration-200 ease-in-out
        ${isTyping || (!newMessage && !filePreview) ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleSendMessage}
                        disabled={isTyping || (!newMessage && !filePreview)}
                      >
                        Send
                      </button>

                      {/* <button
                        className="absolute right-24 bottom-4 text-gray-600"
                        onClick={() => setShowMoreOptions(prev => !prev)}
                      >
                        <EllipsisHorizontalIcon className="h-8 w-6 mr-1" />
                      </button> */}

                      <button className="absolute right-24 bottom-4 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="cursor-pointer text-gray-800"
                        >
                          <PaperClipIcon className="h-7 w-7 mb-0.5" />
                        </label>
                        <input
                          id="file-upload"
                          type="file"
                          className="hidden"
                          onChange={handleFileUpload}
                        />
                      </button>

                      <button
                        className="absolute right-36 bottom-4 text-gray-600"
                        onClick={() => setShowEmojiPicker(prev => !prev)}
                      >
                        <FaceSmileIcon className="w-8 h-8" />
                      </button>

                      {showEmojiPicker && (
                        <div className="absolute right-0 bottom-10 z-10">
                          <EmojiPicker onEmojiClick={handleEmojiSelect} />
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* <div
                  className={`${!showMoreOptions ? "hidden" : ""} absolute right-24 bottom-12 bg-white border border-gray-300 rounded-lg shadow-md p-4 m-2 z-10`}
                >
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer text-gray-800"
                  >
                    <span
                      className="flex flex-justify"
                      onClick={() => setShowMoreOptions(false)}
                    >
                      <PaperClipIcon className="h-4 w-4 mr-2" />
                      Attach File
                    </span>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-gray-500"></div>
      )}
    </div>
  );
};

export default Thread;
