import React from "react";
import { formatDate } from "../../utils/dates";
import { useNavigate } from "react-router-dom";
import GenericAvatar from "../AIInbox/GenericAvatar";

const ItemBox = ({
  task,
  setIsModalVisible,
  setSingleTask,
  handleConversation,
}) => {
  const navigate = useNavigate();

  const viewConversation = conversation => {
    navigate(`/dashboard/ai-inbox?only=${task.conversation_uuid}`);
  };

  return (
    <div
      className="p-4 bg-white border rounded-lg shadow-sm space-y-2"
      key={task.conversation_id}
    >
      <div className="flex items-center space-x-4">
        <div className="w-10 h-10 bg-gray-200 flex items-center justify-center rounded-full text-gray-600 font-semibold">
          <GenericAvatar
            firstName={task.customer?.first_name}
            lastName={task.customer?.last_name}
          />
        </div>
        <div className="flex items-center space-x-2">
          <span className="font-medium text-gray-800">
            {task.customer?.first_name + " " + task.customer?.last_name}
          </span>
          <span className="text-sm text-gray-500">
            {formatDate(task.last_message_time)}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-purple-600 font-medium mb-1">
            {task.summary?.split("Follow-up")[0] || "N/A"}
          </h3>
          <p className="text-sm text-gray-500 font-semibold mb-2">
            {task.action || "N/A"}
          </p>
          <div className="flex items-center space-x-2">
            <span className="text-xs bg-gray-100 text-gray-700 px-2 py-1 rounded-full">
              Ticket #{task.conversation_id}
            </span>
            <span
              className={`text-xs ${task.resolved ? `bg-blue-100 text-blue-600` : `bg-orange-100 text-orange-600`} px-2 py-1 rounded-full`}
            >
              {task.resolved ? `Closed` : `Open`}
            </span>
            <button className="text-xs bg-green-100 text-green-600 px-3 py-1.5 rounded-full hover:bg-green-200">
              Update Account
            </button>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <button
            onClick={() => {
              setSingleTask(prevState => ({
                ...prevState,
                task,
              }));
              setIsModalVisible(true);
            }}
            className="w-10 h-10 border border-purple-400 rounded-lg flex items-center justify-center text-purple-600 hover:bg-purple-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
              />
            </svg>
          </button>
          <button
            onClick={() => viewConversation()}
            className="w-10 h-10 border border-purple-400 rounded-lg flex items-center justify-center text-purple-600 hover:bg-purple-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
          </button>
          <button
            className="w-10 h-10 border border-purple-400 rounded-lg flex items-center justify-center text-purple-600 hover:bg-purple-50"
            onClick={() => {
              handleConversation(task);
            }}
          >
            {task.resolved ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 13.5h3.86a2.25 2.25 0 0 1 2.012 1.244l.256.512a2.25 2.25 0 0 0 2.013 1.244h3.218a2.25 2.25 0 0 0 2.013-1.244l.256-.512a2.25 2.25 0 0 1 2.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 0 0-2.15-1.588H6.911a2.25 2.25 0 0 0-2.15 1.588L2.35 13.177a2.25 2.25 0 0 0-.1.661Z"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ItemBox;
