import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from'./api.js'

const BillingContext = createContext();

export const BillingContextProvider = ({ children }) => {
  const [billingInfo, setBillingInfo] = useState({})

  const fetchBillingInfo = useCallback(async () => {
    try {
      const billingUsage = await api.get("/api/company/usage");
      setBillingInfo(billingUsage.data);
    } catch (error) {
      console.error("Failed to fetch billing data:", error);
    }
  }, []);

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  const resetBilling = useCallback(() => {
    setBillingInfo({});
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  return (
    <BillingContext.Provider value={{billingInfo, resetBilling}}>
      {children}
    </BillingContext.Provider>
  )
}

export const useBillingContext = () => useContext(BillingContext);
