import React, { useState, useEffect } from "react";

import GenericAvatar from "./GenericAvatar";

import api from "../../api.js";

import { formatCompactTime } from "../../utils/dates.js";

const ThreadCard = ({ thread, selected, selectMessage }) => {
  const [messagePreview, setMessagePreview] = useState("");

  useEffect(() => {
    const fetchPreview = async () => {
      try {
        const response = await api.get(
          `/api/conversation/${thread.conversation_uuid}`
        );

        setMessagePreview(response.data[response.data.length - 1].text);
      } catch (error) {
        setMessagePreview("");
        console.error("Failed to fetch preview:", error);
      }
    };

    fetchPreview();
  }, [thread.conversation_uuid]); // Run only when conversation_uuid changes

  const threadEmail = thread => {
    const title =
      thread.customer && thread.customer.first_name && thread.customer.last_name
        ? `${thread.customer.first_name} ${thread.customer.last_name}`
        : "Unknown User";

    if (title.length > 13) {
      return `${title.substring(0, 13)}...`;
    }

    return title;
  };

  return (
    <li
      key={thread.conversation_id}
      className={`relative p-4 cursor-pointer rounded border-b border-slate-200 border-r ${
        selected ? "bg-[#F4F3F1]" : "bg-white"
      } flex flex-col h-full w-full max-h-60 min-h-20`}
      onClick={() => selectMessage(thread)}
    >
      <div className="flex items-start justify-between relative">
        <div className="flex items-start flex-1">
          {thread.customer.picture_url && (
            <div>
              <div className="min-w-8 w-8 h-8 overflow-hidden rounded-full border-2 border-gray-300">
                <img
                  src={thread.customer.picture_url}
                  alt="User Avatar"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )}
          {!thread.customer.picture_url && (
            <GenericAvatar
              firstName={thread.customer.first_name}
              lastName={thread.customer.last_name}
            />
          )}
          <div className="ml-3 flex flex-col w-full">
            <div className="flex items-center justify-between">
              <div className="flex space-x-0">
                <span className="font-bold text-sm text-gray-900 mr-1">
                  {threadEmail(thread)}
                </span>
              </div>
              <span className="text-xs text-gray-500">
                {formatCompactTime(thread.last_message_time)}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 text-sm text-gray-700 line-clamp-3 mt-1 min-w-56">
        {messagePreview}
      </div>
    </li>
  );
};

export default ThreadCard;
