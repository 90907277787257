import React from "react";
import ThreadCard from "./ThreadCard.jsx";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PaginationRow } from "../../components/PaginationRow.jsx";

const MessageList = ({
  selectedThread,
  threads,
  fetchThread,
  currentPage,
  setPageSize,
  setPage,
  totalPages,
  filters,
  setFilters,
  showFilters,
}) => {
  return (
    <div
      className={`min-w-[300px] w-full ${selectedThread && "hidden sm:block"}`}
    >
      {showFilters && (
        <div className="flex justify-between mx-3 my-3">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {filters.handedOff
                  ? "Handed Off"
                  : filters.resolved === false
                    ? "Open"
                    : filters.resolved === true
                      ? "Closed"
                      : "All"}
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 h-5 w-5 text-gray-400"
                />
              </MenuButton>
            </div>
            <MenuItems className="absolute left-0 z-10 mt-2 w-28 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ handedOff: true });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Handed Off
                    </button>
                  )}
                </MenuItem>

                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ resolved: false });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Open
                    </button>
                  )}
                </MenuItem>

                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({ resolved: true });
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      Closed
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setPage(1);
                        setFilters({});
                      }}
                      className={`block w-full text-left px-4 py-2 text-sm ${active ? "bg-gray-100 text-gray-900" : "text-gray-700"}`}
                    >
                      All
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      )}

      <ul className="w-full">
        {threads.map(thread => (
          <ThreadCard
            key={thread.conversation_id}
            thread={thread}
            selected={
              selectedThread &&
              selectedThread.conversation_id === thread.conversation_id
            }
            selectMessage={thread => {
              fetchThread({ thread: thread });
            }}
          />
        ))}
      </ul>

      {totalPages > 1 && (
        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <PaginationRow
            totalPages={totalPages}
            currentPage={currentPage}
            setPage={setPage}
            arrows={false}
          />
        </div>
      )}
    </div>
  );
};

export default MessageList;
