import React from "react";
import "./SuccessOrCancel.css";
import { useNavigate } from "react-router-dom";

const CancelStripe = () => {
  const navigate = useNavigate();

  return (
    <div className="stripe-container">
      <div className="stripe-card">
        <div className="stripe-icon cancel-icon">&#10060;</div>
        <h1 className="text-2xl text-[#333333] mb-2.5">Payment Canceled</h1>
        <p className="text-base text-[#666666] mb-4">
          Your payment process was canceled successfully.
        </p>
        <p className="text-sm text-[#888888]">
          If this was a mistake, you can try again or contact support for
          assistance.
        </p>
        <button
          className="stripe-btn"
          onClick={() => navigate(`/dashboard/account`)}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default CancelStripe;
