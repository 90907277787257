import React from "react";

const TasksNav = ({ tasksTypes, setTaskType, taskType }) => {
  return (
    <div className="mb-6">
      <nav className="flex space-x-1 sm:space-x-4 border-b-2 text-xs sm:text-lg">
        <button
          className={`font-semibold px-3 py-2 rounded-md ${taskType === tasksTypes[0] ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
          onClick={() => setTaskType(tasksTypes[0])}
        >
          Open
        </button>
        <button
          className={`font-semibold px-3 py-2 rounded-md ${taskType === tasksTypes[1] ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
          onClick={() => setTaskType(tasksTypes[1])}
        >
          Closed
        </button>
        <button
          className={`font-semibold px-3 py-2 rounded-md ${taskType === tasksTypes[2] ? "text-primary border-b-2 border-primary rounded-none" : "text-gray-500"}`}
          onClick={() => setTaskType(tasksTypes[2])}
        >
          All Tickets
        </button>
      </nav>
    </div>
  );
};
export default TasksNav;
