import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";

export const PaginationRow = ({
  totalPages,
  currentPage,
  setPage,
  arrows = true,
}) => {
  function generatePagination() {
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const pages = [];

    for (let i = 1; i <= 3; i++) {
      pages.push(i);
    }

    if (currentPage > 4 && currentPage <= totalPages - 3) {
      pages.push("...");
    }

    if (currentPage > 3 && currentPage < totalPages - 2) {
      pages.push(currentPage);
    }

    if (
      (currentPage < totalPages - 3 ||
        (currentPage > 4 && pages.indexOf("...") === -1)) &&
      pages[-1] != "..."
    ) {
      pages.push("...");
    }

    for (let i = totalPages - 2; i <= totalPages; i++) {
      pages.push(i);
    }

    return pages;
  }

  const pagination = generatePagination();

  return (
    <>
      <button
        type="button"
        className={`rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ${
          currentPage === 1
            ? "bg-gray-200 text-gray-400 cursor-not-allowed pointer-none"
            : "hover:bg-gray-50 text-gray-700"
        }`}
        onClick={() => setPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <div className="inline-flex items-center">
          <ArrowLeftIcon className="h-4 w-4 mr-1" />
          {arrows && "Previous"}
        </div>
      </button>

      <ul className="flex space-x-2">
        {pagination.map((page, index) => (
          <li key={index}>
            {page === "..." ? (
              <span className="px-3 py-2 text-gray-500">...</span>
            ) : (
              <button
                onClick={() => setPage(page)}
                className={`px-3 py-2 rounded-md ${
                  currentPage === page
                    ? "bg-gray-50"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
              >
                {page}
              </button>
            )}
          </li>
        ))}
      </ul>

      <button
        onClick={() => setPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 ${
          currentPage === totalPages
            ? "bg-gray-200 text-gray-400 cursor-not-allowed pointer-none"
            : "hover:bg-gray-50 text-gray-700"
        }`}
      >
        <div className="inline-flex items-center">
          {arrows && "Next"}
          <ArrowRightIcon className="h-4 w-4 mr-1" />
        </div>
      </button>
    </>
  );
};
