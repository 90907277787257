import { CloudArrowUpIcon } from "@heroicons/react/24/outline";

import { ReactComponent as LogoUploadAvatar } from "../../../imgs/icons/logoUploadAvatar.svg";

import React, { useRef } from "react";

const BrandLogoUpload = ({ title, subtitle, logo, uploadHandler }) => {
  const fileInputRef = useRef(null);

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-lg mb-0 font-semibold text-gray-900">{title}</h2>
        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={uploadHandler}
        className="hidden"
        id="logo"
        ref={fileInputRef}
      />

      <div className="flex items-center space-x-4">
        <div className="w-24 h-24 flex items-center justify-center ">
          {logo && (
            <div className="relative w-24 h-24 rounded-full border-4 border-white shadow flex items-center justify-center">
              <img
                className="rounded-full object-cover"
                src={logo}
                alt="Uploaded Logo"
              />
            </div>
          )}
          {!logo && <LogoUploadAvatar />}
        </div>

        <div
          className="flex-1"
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click(); // Trigger file input click
            }
          }}
        >
          <div className="border border border-gray-200 rounded-lg p-4 text-center">
            <div>
              <CloudArrowUpIcon
                aria-hidden="true"
                className="h-8 w-8 mx-auto text-gray-700 mb-2 p-1 m-1 rounded border shadow"
              />
            </div>
            <button
              type="button"
              className="text-primary font-medium text-sm hover:underline"
              htmlFor="logo"
            >
              Click to upload
            </button>
            <span className="text-sm text-gray-500"> or drag and drop</span>
            <p className="mt-2 text-xs text-gray-400">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandLogoUpload;
