import React from "react";

const IntegrationBox = ({ integration, handleCardClick }) => {
  return (
    <div
      className="border rounded-xl p-4 bg-white flex flex-col shadow w-full h-auto"
      id="main-div"
    >
      <div className="flex items-center mb-4">
        <div className="relative w-12 h-12 rounded border-4 border-white shadow flex items-center justify-center mr-3">
          <img
            className="rounded object-cover"
            src={integration.icon}
            alt="Uploaded Logo"
          />
        </div>
        <h3 className="text-lg font-semibold text-gray-700">
          {integration.name}
        </h3>
      </div>

      <p className="text-gray-500 text-sm mb-7">{integration.description}</p>
      <hr className="border-gray-300 mb-6 -mx-3.5" />
      <div className="flex justify-between items-center">
        {integration.status === "connected" ? (
          <>
            <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full text-sm">
              Connected
            </span>
            <button className="text-blue-600 underline text-sm ml-2">
              View Integration
            </button>
          </>
        ) : (
          <div>
            {integration.comingSoon === true ? (
              <div className="flex items-center ml-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                  />
                </svg>
                <span>Coming Soon</span>
              </div>
            ) : (
              <button
                className={`px-4 py-2 rounded-md text-white transition duration-300 bg-blue-500 hover:bg-blue-600`}
                onClick={() => handleCardClick(integration)}
              >
                Connect
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationBox;
