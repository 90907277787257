import React from "react";

const Banner = ({ message, type = "info", onClose }) => {
  const typeStyles = {
    info: "bg-blue-100 text-blue-800",
    success: "bg-green-100 text-green-800",
    warning: "bg-yellow-100 text-yellow-800",
    error: "bg-red-100 text-red-800",
  };

  return (
    <div
      className={`flex justify-between items-center p-4 rounded-lg ${typeStyles[type]} shadow-lg`}
    >
      <span>{message}</span>
      {onClose && (
        <button
          className="ml-4 text-sm font-semibold text-gray-600 hover:underline"
          onClick={onClose}
        >
          Close
        </button>
      )}
    </div>
  );
};

export default Banner;
