import React, { useState } from "react";

import { ReactComponent as TitleIcon } from "../../../imgs/icons/chat.svg";
import { ReactComponent as HelpIcon } from "../../../imgs/icons/help.svg";

import { ReactComponent as CloseButton } from "../../../imgs/icons/buttons/close.svg";
import { Switch } from "@headlessui/react";

const AddIntentModal = ({ isOpen, onClose, onSuccess, setLoading, api }) => {
  const [intent, setIntent] = useState({
    type: "",
    description: "",
    category: "Order Management",
    response: "",
    email_collect: true,
    hand_off: true,
    set_live: false,
  });

  if (!isOpen) return null;

  const actionDisabled =
    !intent.type || !intent.description || !intent.category || !intent.response;

  const handleInputChange = (field, value) => {
    setIntent(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createIntent = async evt => {
    setLoading(true);

    try {
      const chatbotUuid = localStorage.getItem("chatbot_uuid");

      await api.post("/api/intents", {
        ...intent,
        chatbot_uuid: chatbotUuid,
      });

      onSuccess();
    } catch (err) {
      console.error("Failed to create intent", err);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg overflow-y-auto max-h-screen">
        <div className="flex justify-between items-center">
          <TitleIcon
            width={60}
            height={60}
            className="p-4 ml-6 mt-6 border rounded-xl"
          />
          <CloseButton className="mr-6 cursor-pointer" onClick={onClose} />
        </div>
        <div className="flex justify-between items-center mx-6 pt-3">
          <h2 className="text-lg font-semibold text-gray-800">Add Intent</h2>
        </div>

        <div className="border-b">
          <p className="mx-6 text-xs text-gray-600">
            Intents & questions are used by your AI Agent to understand a
            specific customer intent and how you would like them to react to it.
            You only need to implement these for questions that require action
            or information from your customer that can’t already be handled by
            your AI Agent.
          </p>
        </div>

        <div className=" border-t border-1 border-red">
          <div key="intent" className="mb-4 mx-6">
            <div className="flex justify-between items-center">
              <div className="flex mt-4 mb-2 text-xs text-gray-800 font-semibold">
                Name
              </div>
            </div>
            <input
              className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
              placeholder="e.g. Refund customer"
              rows={2}
              value={intent.type}
              required
              onChange={e => handleInputChange("type", e.target.value)}
            />

            <div className="flex mt-3 mb-2 text-xs text-gray-800 font-semibold">
              Description *
              <HelpIcon className="ml-1 w-4 h-4" />
            </div>
            <textarea
              className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
              placeholder="e.g. Customer is inquiring about a refund and wants to know more about the process of how to get one."
              rows={4}
              value={intent.description}
              required
              onChange={e => handleInputChange("description", e.target.value)}
            />

            <div className="flex mt-3 mb-1 text-xs text-gray-600 font-semibold">
              Category *
              <HelpIcon className="ml-1 w-4 h-4" />
            </div>
            <select
              className="mt-1 px-2 py-3 rounded-md bg-white border border-gray-300 ring-opacity-50 w-full"
              value={intent.category}
              onChange={e => {
                handleInputChange("category", e.target.value);
              }}
            >
              <option value="Order Management">Order Management</option>
              <option value="Account Management">Account Management</option>
            </select>

            <div className="flex mt-3 mb-2 text-xs text-gray-800 font-semibold">
              AI Agent Response *
              <HelpIcon className="ml-1 w-4 h-4" />
            </div>
            <textarea
              className="px-2 py-3 rounded-md border border-gray-300 ring-opacity-50 w-full"
              placeholder="e.g. We allow refunds up to 30 days after the date of purchase. If you would like a refund, please reply with your order number and the specific items you’d like refunded (or just say entire order, if it’s for the entire order)."
              rows={4}
              value={intent.response}
              required
              onChange={e => handleInputChange("response", e.target.value)}
            />

            <div className="flex mt-3 mb-1 text-sm text-gray-600 font-semibold">
              Collect Email
            </div>
            <div className="flex mb-2 text-xs text-gray-600 justify-between items-center">
              <div className="inline-flex items-center pr-3">
                If this is turned on, after this intent is recognized and the
                response is sent, your AI Agent will collect the user’s email if
                they aren’t already authenticated.
              </div>
              <Switch
                checked={intent.email_collect}
                onChange={() =>
                  handleInputChange("email_collect", !intent.email_collect)
                }
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </div>

            <div className="flex mt-3 mb-1 text-sm text-gray-600 font-semibold">
              Hand Off to Team
            </div>
            <div className="flex mb-2 text-xs text-gray-600 justify-between items-center">
              <div className="inline-flex items-center pr-3">
                If this is turned on, after this intent is recognized and the
                response is sent, your AI Agent will hand off this task to your
                team to execute the final work required.
              </div>
              <Switch
                checked={intent.hand_off}
                onChange={() => handleInputChange("hand_off", !intent.hand_off)}
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </div>

            <div className="flex mt-3 mb-1 text-sm text-gray-600 font-semibold">
              Set live
            </div>
            <div className="flex mb-2 text-xs text-gray-600 justify-between items-center">
              <div className="inline-flex justify-center pr-3">
                You’ll be able to try this out in the Testing section before
                setting it live.
              </div>
              <Switch
                checked={intent.set_live}
                onChange={() => handleInputChange("set_live", !intent.set_live)}
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </div>
          </div>
        </div>

        <div className="flex items-center py-3 border-t">
          <button
            onClick={onClose}
            className="flex items-center justify-center w-1/2 text-md font-bold text-gray-500 hover:bg-gray-100 border shadow-md ml-6 mr-1 py-3 rounded-md"
          >
            Cancel
          </button>
          <button
            className={`flex items-center justify-center w-1/2 text-md leading-5 bg-secondary border border-primary shadow-md ${
              actionDisabled ? "cursor-not-allowed" : "hover:opacity-75"
            } font-bold text-white ml-1 mr-6 py-3 rounded-md`}
            disabled={actionDisabled}
            onClick={createIntent}
            title={actionDisabled ? "Complete form" : ""}
          >
            Add Intent
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddIntentModal;
