import { useState, useCallback } from "react";

export const useBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("info");

  const showBanner = useCallback((msg, bannerType = "info") => {
    setMessage(msg);
    setType(bannerType);
    setIsVisible(true);
  }, []);

  const hideBanner = useCallback(() => {
    setIsVisible(false);
  }, []);

  return { isVisible, message, type, showBanner, hideBanner };
};
