import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import api from "../api.js";
import GlobalLoadingSpinner from "../components/GlobalSpinner.jsx";
import ConfirmationDialog from "../components/ConfirmationDialog";

import MessageList from "./AIInbox/MessageList.jsx";
import Thread from "./AIInbox/Thread.jsx";
import MessageDetails from "./AIInbox/MessageDetails.jsx";
import { fakeConversations } from "./AIInbox/fakeConversations.js";

import { useLocation, useNavigate } from "react-router-dom";
import GenericAvatar from "./AIInbox/GenericAvatar.jsx";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ErrorModal from "../components/ErrorModal.jsx";
import { useBodyScrollLock } from "../hooks/useBodyScrollLock.js";

const AIInbox = () => {
  useBodyScrollLock(); // Lock scrolling at the body level

  const devTestModeOn = process.env.REACT_APP_ENV === "development" && false;

  const chatbotUuid = localStorage.getItem("chatbot_uuid");
  const location = useLocation();
  const navigate = useNavigate();

  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [inResolutionConversationUUID, setInResolutionConversationUUID] =
    useState(null);

  const [filters, setFilters] = useState({
    resolved: false,
    handedOff: false,
  });

  const [conversationUUID, setConversationUUID] = useState(
    new URLSearchParams(location.search).get("only")
  );

  const resolveConversation = async conversationUuid => {
    setInResolutionConversationUUID(conversationUuid);
    setIsConfirmModalVisible(true);
  };

  const confirmResolveConversation = async () => {
    try {
      setLoading(true);

      await api.post("/api/update-conversation", {
        conversation_uuid: inResolutionConversationUUID,
        resolved: true,
      });

      fetchConversations(1, pageSize, filters);
    } catch (err) {
      setErrorModalMsg(
        "Unexpected error - failed to update conversation. Please try again later"
      );
    } finally {
      setLoading(false);
      setIsConfirmModalVisible(false);
    }
  };

  const wsRef = useRef(null);
  let [totalThreads, setTotalThreads] = useState(0);
  const totalPages = Math.ceil(totalThreads / pageSize);

  const sortByLastMessageTime = conversations => {
    return conversations.sort(
      (a, b) =>
        DateTime.fromISO(b.last_message_time, { zone: "utc" }).toMillis() -
        DateTime.fromISO(a.last_message_time, { zone: "utc" }).toMillis()
    );
  };

  const fetchConversations = useCallback(
    async (page, pageSize, filters) => {
      if (!chatbotUuid) {
        console.error(
          "Chatbot UUID is not found in local storage or URL parameters"
        );
        return;
      }
      try {
        setLoading(true);

        if (devTestModeOn) {
          setTotalThreads(fakeConversations.total_conversations);
          setThreads(sortByLastMessageTime(fakeConversations.conversations)); // TODO: sort on the server side
          return;
        } else {
          const apiFilters = {};

          if (filters.resolved) {
            apiFilters.resolved = true;
          }

          if (filters.resolved === false) {
            apiFilters.resolved = false;
          }

          if (filters.handedOff) {
            apiFilters.handed_off = true;
          }

          if (conversationUUID) {
            apiFilters.conversation_uuid = conversationUUID;
          }

          const { data } = await api.get(`/api/conversations/${chatbotUuid}`, {
            params: { page, page_size: pageSize, ...apiFilters },
          });

          setTotalThreads(data.total_conversations);
          setThreads(sortByLastMessageTime(data.conversations)); // TODO: sort on the server side
        }
      } catch (err) {
        console.error("Failed to fetch conversations", err);
      } finally {
        setLoading(false);
      }
    },
    [chatbotUuid, devTestModeOn, conversationUUID, filters]
  );

  useEffect(() => {
    fetchConversations(page, pageSize, filters);
  }, [fetchConversations, page, pageSize, filters, conversationUUID]);

  useEffect(() => {
    if (selectedThread) {
      wsRef.current = new WebSocket(
        `${process.env.REACT_APP_WSS_BASE_URL}/${selectedThread.conversation_uuid}`
      );
      wsRef.current.onmessage = () =>
        fetchThread({ thread: selectedThread, loading: false });
      return () => wsRef.current && wsRef.current.close();
    }
  }, [selectedThread]);

  useEffect(() => {
    if (threads.length) fetchThread({ thread: threads[0] });
  }, [threads]);

  const fetchThread = async ({ thread, loading = true }) => {
    try {
      if (devTestModeOn) {
        setSelectedThread(thread);
        setMessages(fakeConversations.sample_thread);
        return;
      }

      if (loading) setLoading(true);
      const { data } = await api.get(
        `/api/conversation/${thread.conversation_uuid}`
      );
      const formattedMessages = data.map(entry => ({
        attachment: entry.attachment,
        attachmentLink: entry.attachment_url,
        attachmentName: entry.attachment_name,
        message: entry.text,
        sentTime: DateTime.fromISO(entry.created_at, { zone: "utc" })
          .setZone("local")
          .toFormat("EEE d MMM h:mm a"),
        sender:
          entry.direction === "outgoing"
            ? "user"
            : entry.is_human
              ? "admin"
              : "ChatBot",
        direction: entry.direction === "outgoing" ? "outgoing" : "incoming",
      }));
      setSelectedThread(thread);
      setMessages(formattedMessages);
    } catch (err) {
      console.error("Failed to fetch conversation details", err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUUIDFilter = () => {
    setLoading(true);
    setConversationUUID(null);
    navigate("/dashboard/ai-inbox");
    setLoading(false);
  };

  const [errorModalMsg, setErrorModalMsg] = useState(null);

  return (
    <div className="flex flex-col h-full">
      <ErrorModal
        isOpen={errorModalMsg}
        onClose={() => setErrorModalMsg(null)}
        errorMessage={errorModalMsg}
      />
      <GlobalLoadingSpinner loading={loading} />

      <div className="sticky top-0 z-10 h-[80px] bg-white w-full shadow-md">
        <div className="grid sm:grid-cols-[minmax(300px,20vw)_1fr] xl:grid-cols-[minmax(300px,20vw)_1fr_minmax(15vw,20vw)] h-full">
          <div
            className={`flex items-center border-r border-gray-200 px-4 ${selectedThread && "hidden sm:flex"}`}
          >
            <h1 className="text-lg font-bold mb-0 ml-5">
              Messages
              <span className="ml-2 bg-gray-50 border border-gray-300 text-gray-800 text-xs font-medium px-2 py-0.5 rounded-full">
                {totalThreads}
              </span>
            </h1>
          </div>

          <div
            className={`flex justify-between items-center px-4 ${!selectedThread && "hidden sm:flex"}`}
          >
            <div className="flex items-center">
              {selectedThread && selectedThread.customer.picture_url && (
                <div>
                  <div className="min-w-8 w-8 h-8 overflow-hidden rounded-full border-2 border-gray-300">
                    <img
                      src={selectedThread.customer.picture_url}
                      alt="User Avatar"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
              {selectedThread && !selectedThread.customer.picture_url && (
                <GenericAvatar
                  firstName={selectedThread.customer.first_name}
                  lastName={selectedThread.customer.last_name}
                />
              )}
              <div className="ml-2">
                <p className="text-lg mt-2 mb-0 font-bold text-gray-500">
                  {(selectedThread &&
                    selectedThread.customer &&
                    selectedThread.customer.first_name &&
                    selectedThread.customer.last_name &&
                    `${selectedThread.customer.first_name} ${selectedThread.customer.last_name}`) ||
                    "Unknown User"}
                </p>
                <p className="text-xs text-gray-500 border border-gray-300 shadow rounded w-min p-1 px-2 inline-flex items-center space-x-1">
                  <span className="w-2 h-2 bg-gray-400 rounded-full"></span>
                  <span>Offline</span>
                </p>
              </div>
            </div>

            {selectedThread && !selectedThread.resolved && (
              <button
                className="flex justify-center bg-secondary text-white px-4 py-2 rounded-md text-sm font-bold"
                onClick={() =>
                  resolveConversation(selectedThread.conversation_uuid)
                }
              >
                <CheckCircleIcon className="w-5 h-5" />
                <span className="ml-1">Close</span>
              </button>
            )}
          </div>

          <div className="hidden xl:flex justify-start items-center border-l border-gray-200 px-4">
            <h1 className="text-lg font-bold mb-0 ml-5">Details</h1>
          </div>
        </div>
      </div>

      <div className="grid sm:grid-cols-[minmax(300px,20vw)_1fr] xl:grid-cols-[minmax(300px,20vw)_1fr_minmax(15vw,20vw)] h-[calc(100vh-144px)] lg:h-[calc(100vh-80px)]">
        <div
          className={`overflow-y-auto border-r border-gray-200 flex flex-col ${selectedThread && "hidden sm:flex"}`}
        >
          {conversationUUID && (
            <div className="flex justify-between space-x-2 px-4 py-2 bg-gray-100 rounded m-2">
              <span
                className="text-xs mt-1 cursor-pointer"
                title={conversationUUID}
              >
                <span className="font-bold">Conversation: </span>
                {conversationUUID.substring(0, 16)}...
              </span>
              <XMarkIcon
                className="w-5 h-5 cursor-pointer"
                onClick={handleRemoveUUIDFilter}
              />
            </div>
          )}
          <MessageList
            selectedThread={selectedThread}
            threads={threads}
            fetchThread={fetchThread}
            currentPage={page}
            setPage={setPage}
            setPageSize={setPageSize}
            totalPages={totalPages}
            filters={filters}
            setFilters={setFilters}
            showFilters={!conversationUUID}
          />
        </div>

        <div className="overflow-y-auto border-r border-gray-200">
          {selectedThread && (
            <Thread
              selectedThread={selectedThread}
              setSelectedThread={setSelectedThread}
              messages={messages}
              fetchThread={fetchThread}
              chatbotUuid={chatbotUuid}
              api={api}
            />
          )}
        </div>

        <div className="overflow-y-auto hidden xl:block">
          {selectedThread && <MessageDetails selectedThread={selectedThread} />}
        </div>
      </div>

      {isConfirmModalVisible && (
        <ConfirmationDialog
          isVisible={isConfirmModalVisible}
          onClose={() => setIsConfirmModalVisible(false)}
          onCancel={() => setIsConfirmModalVisible(false)}
          onConfirm={() => confirmResolveConversation()}
          title={"Resolve conversation?"}
          description={"Are you sure you want to resolve this thread?"}
          confirmLabel="Yes"
          cancelLabel="Cancel"
          useCase="positive"
        />
      )}
    </div>
  );
};

export default React.memo(AIInbox);
