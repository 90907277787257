import React, { useState } from "react";
import SetupTab from "./components/SetupTab.jsx";
import DeployOptions from "./Deploy/DeployOptions.jsx";
import BasicDeploy from "./Deploy/BasicDeploy.jsx";
import IntermediateDeploy from "./Deploy/IntermediateDeploy.jsx";
import AdvancedDeploy from "./Deploy/AdvancedDeploy.jsx";
import OneClickInstallations from "./Deploy/OneClickInstallations.jsx";

const Deploy = () => {
  const [deployType, setDeployType] = useState("Basic");
  const chatbotUuid = localStorage.getItem("chatbot_uuid") || "";

  // TODO: fetch from company (depends on Branding page)
  const backgroundColor = "#FFFFFF";
  const secondaryColor = "#4F8F00";
  const fontColor = "#000000";
  const companyName = "Sample company name";
  const companyLogo =
    "https://upload.wikimedia.org/wikipedia/commons/8/85/Logo-Test.png";
  // ~ TODO: fetch from company (depends on Branding page)

  return (
    <div className="mt-12 mx-4 md:mx-24" style={{paddingBottom: '10px'}}>
      <SetupTab
        title={"Deploy"}
        subtitle={
          "Use this to deploy your new AI employee to your communication channels. Livechat and email are currently supported and more channels such as SMS, WhatsApp, Facebook Messenger and Instagram Messenger are coming soon."
        }
        activeTab={"deploy"}
      />

      <DeployOptions
        initialSelection={deployType}
        externalHandleSelection={setDeployType}
      />

      {deployType === "Basic" && (
        <BasicDeploy
          tenantId={chatbotUuid}
          backgroundColor={backgroundColor}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      {deployType === "Intermediate" && (
        <IntermediateDeploy
          tenantId={chatbotUuid}
          backgroundColor={backgroundColor}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      {deployType === "Advanced" && (
        <AdvancedDeploy
          tenantId={chatbotUuid}
          backgroundColor={backgroundColor}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
          companyName={companyName}
          companyLogo={companyLogo}
        />
      )}

      <div className="border-t mt-12"></div>

      <section className="mt-12 mb-6">
        <h2 className="text-lg font-semibold mb-0">One-Click Installations</h2>
        <p className="text-gray-600 text-sm">
          Quickly add the chat widget to your website
        </p>

        <OneClickInstallations />
      </section>
    </div>
  );
};

export default Deploy;
