import React from "react";
import "./SuccessOrCancel.css";
import { useNavigate } from "react-router-dom";

const SuccessStripe = () => {
  const navigate = useNavigate();

  return (
    <div className="stripe-container">
      <div className="stripe-card">
        <div className="stripe-icon success-icon">&#10004;</div>
        <h1 className="text-2xl text-[#333333] mb-2.5">Payment Successful!</h1>
        <p>
          Thank you for subscribing. Your subscription has been activated
          successfully.
        </p>
        <button
          className="stripe-btn"
          onClick={() => navigate(`/dashboard/ai-inbox`)}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SuccessStripe;
