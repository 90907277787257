import React, { useState } from "react";

const AdvancedDeploy = ({}) => {
  return (
    <section className="mt-8">
      <h2 className="text-lg font-semibold mb-0">Coming Soon</h2>
      <p className="text-gray-600 text-sm">
        We're working hard to bring this feature to you. Stay tuned!
      </p>
    </section>
  );
};

export default AdvancedDeploy;
