import React, { useState } from "react";

import ConversationSummary from "../../components/ConversationSummary";
import {
  ArrowUpRightIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";

const MessageDetails = ({ selectedThread }) => {
  const [emailInfoMessage, setEmailInfoMessage] = useState("");
  const [phoneInfoMessage, setPhoneInfoMessage] = useState("");

  const getCountryIcon = twoLetterCountryCode => {
    if (!twoLetterCountryCode) {
      return <div className="w-6 h-6 bg-gray-200 rounded-full"></div>;
    }

    const flagUrl = `https://flagcdn.com/w40/${twoLetterCountryCode.toLocaleLowerCase()}.png`;

    return (
      <img
        src={flagUrl}
        alt={`${twoLetterCountryCode} flag`}
        className="w-6 h-6 rounded-full object-cover"
      />
    );
  };

  const customer = selectedThread.customer;

  const customerData = customer
    ? {
        location: customer.country
          ? `${customer.city}, ${customer.state}, ${customer.country}`
          : "Unknown location",
        name: customer.first_name
          ? `${customer.first_name} ${customer.last_name}`
          : "Unknown Name",
        email: customer.email,
        url: customer.url,
        phone: customer.phone,
      }
    : {};

  return (
    <div className="bg-white p-4 hidden xl:block">
      {selectedThread && (
        <div className="w-full p-4 bg-white rounded-lg">
          <div>
            <p className="text-xs font-semibold text-gray-500">Name</p>
            <h2 className="text-lg font-bold text-gray-600">
              {customerData.name}
            </h2>
          </div>
          <div className="mt-4">
            <p className="text-xs font-semibold text-gray-500">Location</p>
            <div className="flex items-center space-x-2">
              <span>{getCountryIcon(selectedThread.twoLetterCountryCode)}</span>
              <p className="text-sm text-gray-700 m-0">
                {customerData.location}
              </p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-xs font-semibold text-gray-500">Current URL</p>
            <a
              href={customerData.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-primary font-bold hover:underline flex items-center space-x-1"
            >
              {customerData.url ? (
                <>
                  {customerData.url}
                  <ArrowUpRightIcon className="ml-1 h-4 w-4" />
                </>
              ) : (
                "Not available"
              )}
            </a>
          </div>
          <div className="mt-4">
            <p className="text-xs font-semibold text-gray-500">Email</p>
            <button
              className="text-sm text-primary font-bold hover:underline flex items-center space-x-1"
              onClick={() => {
                if (!customerData.email) {
                  return;
                }

                navigator.clipboard.writeText(customerData.email).then(() => {
                  setEmailInfoMessage("Copied!");
                  setTimeout(() => setEmailInfoMessage(""), 500);
                });
              }}
            >
              {customerData.email ? (
                <>
                  {customerData.email}
                  <DocumentDuplicateIcon className="ml-1 h-4 w-4" />
                </>
              ) : (
                "Not available"
              )}
            </button>
            {emailInfoMessage && (
              <p className="text-sm text-primary">{emailInfoMessage}</p>
            )}
          </div>

          <div className="mt-4">
            <p className="text-xs font-semibold text-gray-500">Phone</p>
            <button
              className="text-sm text-primary font-bold hover:underline flex items-center space-x-1"
              onClick={() => {
                if (!customerData.phone) {
                  return;
                }

                navigator.clipboard.writeText(customerData.phone).then(() => {
                  setPhoneInfoMessage("Copied!");
                  setTimeout(() => setPhoneInfoMessage(""), 500);
                });
              }}
            >
              {customerData.phone ? (
                <>
                  {customerData.phone}
                  <DocumentDuplicateIcon className="ml-1 h-4 w-4" />
                </>
              ) : (
                "Not available"
              )}
            </button>
            {phoneInfoMessage && (
              <p className="text-sm text-primary">{phoneInfoMessage}</p>
            )}
          </div>

          <div className="mt-4">
            <p className="text-xs font-semibold text-gray-500">
              Summary of Conversation
            </p>
            <p className="text-sm text-gray-700 mt-1">
              <ConversationSummary
                conversation={selectedThread}
                hideTitle={true}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageDetails;
