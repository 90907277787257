import React from "react";

import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import SpotifyPNG from "../../../imgs/shopify.png";
import WordpressPNG from "../../../imgs/wordpress.png";
import WebflowPNG from "../../../imgs/webflow.png";

const OneClickInstallations = ({}) => {
  const integrationsComingSoon = [
    {
      logo: SpotifyPNG,
      title: "Shopify",
      description: "Add the chat widget to your Shopify page",
    },
    {
      logo: WordpressPNG,
      title: "Wordpress",
      description: "Add the chat widget to your Wordpress page",
    },
    {
      logo: WebflowPNG,
      title: "Webflow",
      description: "Add the chat widget to your Webflow page",
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-12">
      {integrationsComingSoon.map((item, index) => (
        <div
          key={index}
          className="flex flex-col justify-between border border-gray-200 rounded-xl shadow-md bg-white"
        >
          <div className="flex items-center px-6 pt-6 pb-4">
            <div className="w-12 h-12 mr-3 rounded-lg border-2 border-grey-300 overflow-hidden flex items-center justify-center">
              <img
                src={item.logo}
                alt={`${item.title} logo`}
                className="w-full h-full object-cover"
              />
            </div>
            <h3 className="text-lg mt-2 font-semibold text-gray-800">
              {item.title}
            </h3>
          </div>

          <p className="mt-2 px-6 text-sm text-gray-600">{item.description}</p>

          <div className="flex p-4 items-center justify-end border-t mt-2 text-sm text-gray-500">
            <RocketLaunchIcon aria-hidden="true" className="size-6 mr-2" />
            <span className="font-semibold">Coming Soon</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OneClickInstallations;
